import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import DeleteModal from "../../components/Common/DeleteModal"
import { Col, Container, Row } from "reactstrap"
import { Button } from "primereact/button"

import Pagination from "../../components/Common/Pagination"
import { useSelector, useDispatch } from "react-redux"
import {
  getCustomers,
  getCategoriesCustomer,
  deleteCustomer,
} from "../../store/actions"
import FormModal from "./FormModal"
import DetailModal from "./DetailModal"
import { handleValidDate } from "../../common/common"
import ToolBar from "components/Toolbar"

const CustomerIndex = props => {
  const refDetail = useRef(null)
  const dispatch = useDispatch()
  const { customers, isDone } = useSelector(state => ({
    customers: state.Customer.customers,
    isDone: state.Customer.isDone,
  }))
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maKH !== row.maKH
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [staffForm, setStaffForm] = useState([])
  const [dataDetail, setDataDetail] = useState({})
  const [modalDelete, setModalDelete] = useState({})
  const [modalDetail, setModalDetail] = useState(false)
  const [page, setPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(20)
  const [item, setItem] = useState([])
  const [textSearch, setTextSearch] = useState("")
  useEffect(() => {
    dispatch(getCategoriesCustomer())
  }, [])
  useEffect(() => {
    if (isDone === true) {
      dispatch(
        getCustomers({
          inputSearch: "",
          MaTT: 0,
          MaNVCS: 0,
          MaNKH: 0,
          Offset: page,
          Limit: itemPerPage,
        })
      )
      setItem([])
    }
  }, [isDone])
  useEffect(() => {
    dispatch(
      getCustomers({
        inputSearch: "",
        MaTT: 0,
        MaNVCS: 0,
        MaNKH: 0,
        Offset: page,
        Limit: itemPerPage,
      })
    )
    setItem([])
  }, [page, itemPerPage])
  const StaffColumns = toggleModal => [
    {
      dataField: "stt",
      text: "STT",
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.stt}
        </Link>
      ),
    },
    {
      dataField: "maKH",
      text: "Xem",
      sort: true,
      formatter: (cellContent, row) => (
        <Link
          to="#"
          onClick={() => toggleDetail(row)}
          className="text-body fw-bold"
        >
          <i className="mdi mdi-17px mdi-eye"></i>
        </Link>
      ),
    },
    {
      dataField: "tenTT",
      text: `${props.t("Status")}`,
      sort: true,
    },
    {
      dataField: "tenKH",
      text: `${props.t("Customer Name")}`,
      sort: true,
    },
    {
      dataField: "ngaySinh",
      text: `${props.t("Ngày sinh")}`,
      sort: true,
    },
    {
      dataField: "soCMND",
      text: `${props.t("Số CMND")}`,
      sort: true,
    },
    {
      dataField: "diDong",
      text: `${props.t("Di động")}`,
      sort: true,
    },
    {
      dataField: "diDong2",
      text: `${props.t("Di động 2")}`,
      sort: true,
    },
    {
      dataField: "email",
      text: `${props.t("Email")}`,
      sort: true,
    },
    {
      dataField: "tenNKH",
      text: `${props.t("Tên NKH")}`,
      sort: true,
    },
    {
      dataField: "tenNguon",
      text: `${props.t("Tên nguồn")}`,
      sort: true,
    },
    {
      dataField: "diaChi",
      text: `${props.t("Địa chỉ")}`,
      sort: true,
    },
    {
      dataField: "thuongTru",
      text: `${props.t("Thường trú")}`,
      sort: true,
    },
    {
      dataField: "nhanVienCS",
      text: `${props.t("Nhân viên CS")}`,
      sort: true,
    },
    {
      dataField: "ngayDangKy",
      text: `${props.t("Ngày nhập")}`,
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.ngayDangKy),
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const toggleDetail = item => {
    setDataDetail({ ...item })
    setModalDetail(!modalDetail)
  }
  const handleEdit = () => {
    setStaffForm({ ...item[0] })
    setIsEdit(true)
    toggle()
  }
  const toggleDelete = () => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
    })
  }
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {
    const arrDelete = []
    itemDL?.map(i => {
      arrDelete.push(i.maKH)
    })
    if (arrDelete.length > 0) {
      dispatch(deleteCustomer({ MaKH: arrDelete }))
    }
  }, [])

  const handleAdd = () => {
    setStaffForm([])
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const onClickchangePage = i => {
    setPage(i)
  }
  const onClickChangeItemPerPage = e => {
    setPage(1)
    setItemPerPage(Number(e.target.value))
  }
  const onChangeTextSearch = e => {
    setTextSearch(e.target.value)
    // let time

    // setTimeout(() => {
    //   time = 1
    // }, [2000])
    // if (time === 1) console.log(time)
  }
  useEffect(() => {
    let time
    if (!time) {
      time = setTimeout(() => {
        dispatch(
          getCustomers({
            inputSearch: textSearch,
            MaTT: 0,
            MaNVCS: 0,
            MaNKH: 0,
            Offset: page,
            Limit: itemPerPage,
          })
        )
        // setItem([])
      }, [1000])
    }
    return () => {
      clearTimeout(time)
    }
  }, [textSearch])

  return (
    <React.Fragment>
      <DeleteModal data={modalDelete} toggle={toggleDelete} />
      <FormModal ref={refDetail} />
      <DetailModal
        className="vh-100"
        isOpen={modalDetail}
        toggle={toggleDetail}
        data={dataDetail}
      />
      <div className="page-content">
        <MetaTags>
          <title>Khách hàng | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                <Button
                  label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  // onClick={handleAdd}
                  onClick={() => refDetail.current.show()}
                />
                {item.length === 1 && (
                  <Button
                    label="Sửa"
                    icon="mdi mdi-pencil"
                    className="p-button-outlined p-button-warning mr-1"
                    onClick={handleEdit}
                  />
                )}
                {item.length >= 1 && (
                  <Button
                    label="Xóa"
                    icon="mdi mdi-delete"
                    className="p-button-outlined p-button-danger mr-1"
                    onClick={handleDelete}
                  />
                )}
              </div>
            )}
            right={() => (
              <div className="search-box  d-inline-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    id="staticEmail2"
                    placeholder="SĐT, Tên KH"
                    onChange={onChangeTextSearch}
                    value={textSearch}
                  />
                  {/* <i className="bx bx-search-alt search-icon" /> */}
                </div>
              </div>
            )}
          />
          <ToolkitProvider
            keyField="maKH"
            data={customers === "[]" ? new Array([]) : customers}
            columns={StaffColumns(toggle)}
            bootstrap4
            // search
          >
            {toolkitProps => (
              <React.Fragment>
                <div className="table-responsive table-scrollable shadow-2">
                  <BootstrapTable
                    keyField="maKH"
                    responsive
                    bordered={true}
                    striped={false}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    classes={
                      "table align-middle  table-nowrap table-check table-hover table-fixed table-striped table-bordered"
                    }
                    headerWrapperClasses={"table-light"}
                    {...toolkitProps.baseProps}
                    // {...paginationTableProps}
                  />
                </div>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <select
                        className="form-select"
                        value={itemPerPage}
                        onChange={e => onClickChangeItemPerPage(e)}
                      >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </select>
                    </div>
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      {/* <PaginationListStandalone
                                    {...paginationProps}
                                  /> */}
                      <Pagination
                        totalPage={Math.ceil(
                          customers[0]?.totalRows / itemPerPage
                        )}
                        changePage={onClickchangePage}
                      />
                    </Col>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

CustomerIndex.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CustomerIndex))
