import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import { handleValidDate } from "../../common/common"
import { Col, Container, Row } from "reactstrap"
import Pagination from "../../components/Common/Pagination"
import { ProjectService } from "services/Project"
import { Button } from "primereact/button"
import Project_Detail from "./Project_Detail"
import LoadingBar from "components/LoadingBar"
import Category_ByProject from "./Categories/Category_ByProject"
import ToolBar from "components/Toolbar"
import CommonFunction from "../../components/CommonFunction"
import { Tooltip } from "primereact/tooltip"

const Project = props => {
  const refDetail = useRef(null)
  const history = useHistory()
  const refCategories = useRef(null)
  const [loading, setLoading] = useState(false)
  const defaultCondition = {
    // TuNgay: "2019-01-04",
    // DenNgay: "2024-10-13",
    inputSearch: "",
    // isProject: true,
    // DuAn: [],
    Offset: 1,
    Limit: 20,
  }
  const [data, setData] = useState([])
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    // _filter.DuAn = _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ProjectService.getList(_filter)
    setData(res?.data || [])
    setLoading(false)
  }
  useEffect(() => {
    filter(filterCondition)
  }, [])

  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "search":
        _filter.inputSearch = v
        break
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  const remove = async item => {
    CommonFunction.confirmRemove({
      message: "Bạn có chắc muốn xóa bản ghi?",
      header: "Xác nhận",
      accept: async () => {
        let _res = await ProjectService.removeProject({ MaDA: [item.maDA] })
        if (_res.status === 2000) {
          await filter(filterCondition)
        }
      },
    })
  }
  const onClickSoDo = (row) =>{
    history.push(`/so-do-phan-lo/${row.maDA}`)
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row, i) => (
          <Link to="#" className="text-body fw-bold">
            {i + 1}
          </Link>
        ),
      },
      {
        dataField: "tenDA",
        text: "Tên dụ án",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span
              className="link"
              onClick={() => refCategories.current.view(row)}
            >
              {row.tenDA}
            </span>
          )
        },
      },
      {
        dataField: "tenVietTat",
        text: "Tên viết tắt",
        sort: true,
      },
      {
        dataField: "diaChi",
        text: "Địa chỉ",
        sort: true,
      },

      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
      },
      {
        dataField: "nguoiNhap",
        text: "Người tạo",
        sort: true,
      },
      {
        text: "Ngày tạo",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
      },

      {
        style: {
          backgroundColor: "white",
          position: "sticky",
          right: 0,
          paddingLeft: "0px",
        },
        headerStyle: {
          backgroundColor: "rgb(236, 240, 247)",
          position: "sticky",
          right: 0,
        },
        formatter: (cellContent, row) => (
          <div
            className="d-flex gap-3"
            style={{ boxShadow: "-5px 0 5px -5px #333" }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => onClickSoDo(row)}
              className="text-success ps-3 tooltip-sodo"
              data-pr-tooltip="Xem sơ đồ phân lô"
            >
              <i className="pi pi-building" />
            </div>
            <Tooltip target=".tooltip-sodo" />
            <div
              style={{ cursor: "pointer" }}
              className="text-warning "
              onClick={() => refDetail.current.update(row)}
            >
              <i className="pi pi-pencil" />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => remove(row)}
              className="text-danger"
            >
              <i className="pi pi-trash" />
            </div>
          </div>
        ),
      },
    ]
  }, [])
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0
      ? {
          background: "#f6f8fb",
        }
      : null
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const afterSubmit = async () => {
    await filter(filterCondition)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Hợp đồng| Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                <Button
                  label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  onClick={() => refDetail.current.create()}
                />
              </div>
            )}
          />

          <React.Fragment>
            <LoadingBar loading={loading} />
            <div
              className="table-responsive table-scrollable shadow-2"
              style={
                {
                  // height: window.innerHeight
                }
              }
            >
              <BootstrapTable
                data={data === "[]" ? new Array([]) : data}
                columns={Columns()}
                keyField="maPGC"
                responsive
                bordered={true}
                striped={false}
                defaultSorted={defaultSorted}
                rowStyle={rowStyle}
                // selectRow={selectRow}
                // rowClasses ={rowClasses}
                classes={
                  "table align-middle  table-nowrap table-check table-hover table-fixed "
                }
                headerWrapperClasses={"table-light"}
              />
            </div>

            <Row className="align-items-md-center mt-30">
              <Col className="inner-custom-pagination d-flex">
                <div>
                  <select
                    className="form-select"
                    value={filterCondition.Limit}
                    onChange={e =>
                      onChangeFilter("itemPerPage", Number(e.target.value))
                    }
                  >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                  </select>
                </div>
                <Col className="pagination pagination-rounded justify-content-end  inner-custom-pagination">
                  <Pagination
                    totalPage={Math.ceil(
                      data[0]?.totalRows / filterCondition.Limit
                    )}
                    changePage={i => onChangeFilter("page", i)}
                  />
                </Col>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
      <Category_ByProject ref={refCategories} />
      <Project_Detail ref={refDetail} afterSubmit={afterSubmit} />
    </React.Fragment>
  )
}

Project.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Project))
