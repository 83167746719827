import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
  handleValidHour,
  formatDateTime,
  formatTime,
} from "../../../common/common"
import { Button } from "primereact/button"
import {
  Col,
  Container,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import { ContractService } from "services/Contract"
import ToolBar from "components/Toolbar"
import LoadingBar from "components/LoadingBar"
import { HopDongService } from "services/Hopdong"
import { Tooltip } from "primereact/tooltip"
import { AgencyService } from "services/Agency"
import { Tag } from "antd"
import FilterLockCan from "./FilterLockCan"
import FormDetail from "./FormDetail"
const DanhSachLockCan = props => {
  let preyear = new Date().setFullYear(new Date().getFullYear() - 5)
  const defaultCondition = {
    TuNgay: new Date(preyear),
    DenNgay: new Date(),
    inputSearch: "",
    DuAn: "",
    Offset: 1,
    Limit: 50,
  }
  const history = useHistory()
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [item, setItem] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const refDetail = useRef(null)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.MaPDC !== row.MaPDC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const handleBooking = async maSP => {
    // const _res = await AgencyService.ForSale.getByID({ MaSP: maSP })
    history.push("/banggiatructuyen/addgiucho/" + maSP)
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      {
        dataField: "tinhTrang",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          // <div
          // className="shadow-4 rounded"
          //   style={{
          //     backgroundColor: {},
          //     padding: "5px",
          //   }}
          // >
          //   {row.tinhTrang}
          // </div>
          <Tag style={{ color: "#333" }}  color={row.tinhTrang === "Unlock" ? "green" : "red"}>
            {" "}
            {row.tinhTrang === "Unlock" ? "Mở bán" : "Khóa"}
          </Tag>
        ),
      },
      {
        dataField: "soPhieu",
        text: "Số phiếu",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span
              onClick={() => refDetail.current.show(row?.id)}
              className="link"
            >
              {row.soPhieu}
            </span>
          )
        },
      },
      {
        dataField: "tenDA",
        text: "Tên dự án",
        sort: true,
      },
      {
        dataField: "kyHieu",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "ngayLock",
        text: "Thời gian lock",
        sort: true,
        formatter: (cellContent, row) => formatDateTime(row.ngayLock),
      },
      {
        dataField: "thoiGianConLai",
        text: "Thời gian lock còn lại",
        sort: true,
        formatter: (cellContent, row) => formatTime(row.thoiGianConLai),
      },
      {
        dataField: "nhanVienNhap",
        text: "Nhân viên",
        sort: true,
      },
      {
        dataField: "tenCT",
        text: "Sàn",
        sort: true,
      },

      {
        style: {
          backgroundColor: "white",
          position: "sticky",
          right: 0,
          paddingLeft: "0px",
        },
        headerStyle: {
          backgroundColor: "rgb(236, 240, 247)",
          position: "sticky",
          right: 0,
        },
        formatter: (cellContent, row) => (
          <div
            className="d-flex gap-3"
            style={{ boxShadow: "-5px 0 5px -5px #333" }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleBooking(row.maSP)}
              className="text-success ps-3 tooltip-sodo"
              data-pr-tooltip="Booking"
            >
              <i className="pi pi-check" />
            </div>
            <Tooltip target=".tooltip-sodo" />
          </div>
        ),
      },
    ]
  }, [])
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    _filter.DuAn =
      _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await HopDongService.DanhSachLookCan.getDanhSach(_filter)
    setData(res.data || [])
    setLoading(false)
  }
  useEffect(() => {
    ;(async () => {
      await filter(filterCondition)
    })()
  }, [])
  const handleEdit = () => {}
  const onClickXemAnh = () => {}
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {}, [])

  const handleAdd = () => {}
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  // useEffect(() => {
  //   let time
  //   if (!time) {
  //     time = setTimeout(() => {
  //       dispatch(
  //         getDatCoc({
  //           TuNgay: dateFrom,
  //           DenNgay: dateTo,
  //           DuAn: maDuAn,
  //           MaTT: maTT,
  //           MaKhu: maKhu,
  //           inputSearch: textSearch,
  //           Offset: page,
  //           Limit: itemPerPage,
  //         })
  //       )
  //       setItem([])
  //     }, [1000])
  //   }
  //   return () => {
  //     clearTimeout(time)
  //   }
  // }, [textSearch])
  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Danh sách lock căn| Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                <Button
                  label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  onClick={handleAdd}
                />
                {item.length === 1 && (
                  <Button
                    label="Sửa"
                    icon="mdi mdi-pencil"
                    className="p-button-outlined p-button-warning mr-1"
                    onClick={handleEdit}
                  />
                )}
                {item.length >= 1 && (
                  <Button
                    label="Xóa"
                    icon="mdi mdi-delete"
                    className="p-button-outlined p-button-danger mr-1"
                    onClick={handleDelete}
                  />
                )}
                {item.length >= 1 && (
                  <ButtonDropdown
                    className="me-2"
                    isOpen={modalDropBtn}
                    toggle={() => toggleDropdownBtn()}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn btn-info rounded"
                    >
                      Hành động <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {item.length === 1 && (
                        <React.Fragment>
                          <DropdownItem onClick={() => onClickXemAnh()}>
                            <i className="bx bx-search-alt me-1" />
                            Xem ảnh
                          </DropdownItem>
                          <DropdownItem onClick={() => onClickGiuCho()}>
                            <i className="bx bx-search-alt me-1" />
                            Giữ chỗ
                          </DropdownItem>
                        </React.Fragment>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </div>
            )}
            right={() => (
              <FilterLockCan
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            )}
          />
          <ToolkitProvider
            keyField="MaPDC"
            bordered={true}
            data={data}
            columns={Columns()}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <LoadingBar loading={loading} />
                    <div className="table-responsive table-scrollable">
                      <BootstrapTable
                        keyField="MaPDC"
                        responsive
                        bordered={true}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={
                          "table align-middle  table-nowrap table-check table-hover table-fixed "
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <select
                        className="form-select"
                        value={filterCondition.Limit}
                        onChange={e =>
                          onChangeFilter("itemPerPage", Number(e.target.value))
                        }
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </select>
                    </div>
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <Pagination
                        totalPage={Math.ceil(
                          data[0]?.TotalRows / filterCondition.Limit
                        )}
                        changePage={i => onChangeFilter("page", i)}
                      />
                    </Col>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
      <FormDetail ref={refDetail} />
    </React.Fragment>
  )
}

DanhSachLockCan.propTypes = {
  customers: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DanhSachLockCan))
