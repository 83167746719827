import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import { TabView, TabPanel } from "primereact/tabview"
import { Card, CardBody } from "reactstrap"
import { ContractService } from "services/Contract"
import { ProjectService } from "services/Project"
import BootstrapTable from "react-bootstrap-table-next"
import { Format_Currency, handleValidDate } from "common/common"
import { Button } from "primereact/button"
import { AgencyService } from "services/Agency"
import Category_ByKhu from "./Category_ByKhu"
import Category_Detail from "./Category_Detail"
import CommonFunction from "components/CommonFunction"
function Category_ByProject(props, ref) {
  const refFunctionMode = useRef(null)
  const refDetail = useRef(null)
  const refCategories = useRef(null)
  const [show, setShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [data, setData] = useState({})
  const [tableData, setTableData] = useState([])
  useImperativeHandle(ref, () => ({
    view: async _item => {
      refFunctionMode.current = "view"
      await filter(0, _item.maDA);
      setData(_item);
      setShow(true);
    },
  }))
  const cancel = () => {
    setShow(false)
    setActiveTab(0)
    setData({})
  }
  const filter = async (tab, maDA) => {
    let _data = _.cloneDeep(tableData)
    switch (tab) {
      case 0: // khu
        let _resKhu = await ProjectService.getKhu({ MaDA: data.maDA || maDA })
        _data = _resKhu?.data
        break
      case 1: // Phòng ngủ
        let _resPN = await AgencyService.ForSale.getPhongNgu({
          MaDA: data.maDA || maDA,
        })
        _data = _resPN?.data
        break
      case 2: // view
        let _resView = await AgencyService.ForSale.getView({
          MaDA: data.maDA || maDA,
        })
        _data = _resView?.data
        break
      case 3: // loại bđs
        let _resBDS = await AgencyService.ForSale.getLoaiBDSByMaDA({
          MaDA: data.maDA || maDA,
        })
        _data = _resBDS?.data
        break
      default:
        break
    }
    setTableData(_data)
  }
  const renderKhu = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message : "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept:async ()=>{
          let _res = await ProjectService.removeKhu({ maKhu: data.MaKhu })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        }
      });
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        text: "Tên khu",
        formatter: (cellContent, row) => {
          return (
            <span
              onClick={() => refCategories.current.view(row)}
              className="link"
            >
              {row.TenKhu}
            </span>
          )
        },
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add({
                  tenKhu: row.TenKhu,
                  maKhu: row.MaKhu,
                  maDA: data.maDA,
                },activeTab)
              }
            />
            <Button
              className="p-button-success p-button-text text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="MaKhu"
        responsive
        bordered={true}
        columns={columns}
        className="w-full h-full"
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed w-full"
        }
        headerWrapperClasses="table-light"
      />
    )
  }
  const renderPhongNgu = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message : "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept:async ()=>{
          let _res = await AgencyService.ForSale.removePhongNgu({ iD: data.ID })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        }
      });
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenLoaiPN",
        text: "Tên phòng ngủ",
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add({
                  tenLoaiPN: row.TenLoaiPN,
                  iD: row.ID,
                  maDA: data.maDA,
                },activeTab)
              }
            />
            <Button
              className="p-button-success p-button-text text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="ID"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderView = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message : "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept:async ()=>{
          let _res = await AgencyService.ForSale.removeView({ iD: data.ID })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        }
      });
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenVeiw",
        text: "Tên view",
      }, 
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add({
                  tenVeiw: row.TenVeiw,
                  iD: row.ID,
                  maDA: data.maDA,
                },activeTab)
              }
            />
            <Button
              className="p-button-success p-button-text  text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="ID"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderLoaBDS = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message : "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept:async ()=>{
          let _res = await AgencyService.Categories.removeLoaiBDS({ maLBDS: data.MaLBDS })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        }
      });
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenLBDS",
        text: "Tên loại bất động sản",
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add({
                  tenLBDS: row.TenLBDS,
                  maLBDS: row.MaLBDS,
                  maDA: data.maDA,
                },activeTab)
              }
            />
            <Button
              className="p-button-success p-button-text  text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="MaLBDS"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const afterSubmit = async () => {
    await filter(activeTab)
  }
  try {
    return (
      <Dialog
        visible={show}
        header="Danh mục theo dự án"
        style={{ width: "40%", height: "70%", position: "relative" }}
        className={` ${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        onHide={() => cancel()}
        maximizable
        // maximized
      >
        <div
          style={{
            height: "35px",
            background: "#ffffff",
            position: "absolute",
            top: 39,
            width: "100%",
            zIndex: 4,
          }}
          className="shadow-2 rounded flex align-items-center"
        >
          <Button
            label="Thêm"
            icon="pi pi-plus"
            className="p-button-success p-button-text"
            onClick={() => refDetail.current.add({ maDA: data?.maDA },activeTab)}
          />
        </div>
        <Card className="mb-0 mt-4">
          <CardBody>
            <TabView
              activeIndex={activeTab}
              onTabChange={e => {
                setActiveTab(e.index)
                filter(e.index)
              }}
            >
              <TabPanel header="Khu">
                <div className="table-responsive">{renderKhu()}</div>
              </TabPanel>

              <TabPanel header="Phòng ngủ">
                <div className="table-responsive">{renderPhongNgu()}</div>
              </TabPanel>
              <TabPanel header="View">
                <div className="table-responsive">{renderView()}</div>
              </TabPanel>
              <TabPanel header="Loại bất động sản">
                <div className="table-responsive">{renderLoaBDS()}</div>
              </TabPanel>
            </TabView>
          </CardBody>
        </Card>
        <Category_ByKhu ref={refCategories} maDA ={data.maDA} />
        <Category_Detail ref={refDetail} afterSubmit={afterSubmit} />
      </Dialog>
    )
  } catch (error) {}
}
Category_ByProject = forwardRef(Category_ByProject)
export default Category_ByProject
