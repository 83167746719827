import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
} from "../../../common/common"
import { Button } from "primereact/button"
import LoadingBar from "components/LoadingBar"
import { Col, Container } from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import BangGia_Filter from "./BangGia_Filter"
import ToolBar from "components/Toolbar"
import { ProjectService } from "services/Project"
import { StaffService } from "services/Staff"
import { Tag } from "antd"
const BangGia = props => {
  const defaultCondition = {
    inputSearch: "",
    BangGia: "",
    MaDA: -1,
    PageIndex: 1,
    PageSize: 20,
  }
  const refDetail = useRef(null)
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [permission, setPermision] = useState([])

  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row, i) => (
          <div className="text-body fw-bold">{i + 1}</div>
        ),
        footer: "",
      },
      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <Tag style={{ color: "#333" }}  color={row.mauNen}>{row.tenTT}</Tag>
        ),
        footer: "",
      },

      {
        dataField: "tenDA",
        text: "Dự án",
        sort: true,
        footer: "",
      },
      {
        dataField: "tenKhu",
        text: "Khu",
        sort: true,
        footer: "",
      },
      {
        dataField: "kyHieu",
        text: "Mã sản phẩm",
        sort: true,
        footer: "",
      },
      {
        dataField: "khoa",
        text: "Khóa",
        sort: true,
        footer: "",
        formatter: (cellContent, row) => (
          <span>{row.khoa ? "Khóa" : "Không khóa"}</span>
        ),
      },
      {
        text: "% VAT",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.vatch),
        footer: "",
      },
      {
        dataField: "phongNgu",
        text: "Số phòng ngủ",
        sort: true,
        footer: "",
      },
      {
        dataField: "lauSo",
        text: "Tầng",
        sort: true,
        footer: "",
      },
      {
        dataField: "tenVT",
        text: "Vị trí",
        sort: true,
        footer: "",
      },
      {
        dataField: "dtTimTuong",
        text: "S T.Tường",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dtTimTuong),
        footer: "",
      },
      {
        text: "S T.Thủy",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichTT),
        footer: "",
      },
      {
        text: "Đơn giá T.Thủy",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.giaVaoHD),
        footer: "",
      },
      {
        text: "Đơn giá chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.giacoso),
        footer: "",
      },
      {
        dataField: "giaBan",
        text: "Tổng chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.giaBan),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },

      {
        dataField: "vatchtien",
        text: "Tiền VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.vatchtien),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "tongGiaCanHo",
        text: "Tổng giá gồm VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaCanHo),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "ptPhiBaoTri",
        text: "% PBT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.ptPhiBaoTri),
        footer: "",
      },
      {
        dataField: "phiBaoTri",
        text: "Phí bảo trì",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.phiBaoTri),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },

      {
        dataField: "tongGiaGomPBT",
        text: "Tổng giá gồm PBT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaGomPBT),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "nguoiNhap",
        text: "Người tạo",
        sort: true,
        footer: "",
      },
      {
        text: "Ngày tạo",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
        footer: "",
      },
      // {
      //   dataField: "nguoiSua",
      //   text: "Người sửa",
      //   sort: true,
      //   footer: "",
      // },
      // {
      //   text: "Ngày sửa",
      //   sort: true,
      //   formatter: (cellContent, row) => handleValidDate(row.ngayCN),
      //   footer: "",
      // },
      {
        style: {
          backgroundColor: "white",
          position: "sticky",
          right: 0,
          paddingLeft: "0px",
        },
        headerStyle: {
          backgroundColor: "rgb(236, 240, 247)",
          position: "sticky",
          right: 0,
        },
        formatter: (cellContent, row) => (
          <div
            className="d-flex gap-3"
            style={{ boxShadow: "-5px 0 5px -5px #333" }}
          >
            <Link
              to="#"
              className="text-warning ps-3"
              // onClick={() => refDetail.current.update(row)}
            >
              <i className="pi pi-pencil" />
            </Link>
            <Link to="#" className="text-danger">
              <i className="pi pi-trash" />
            </Link>
          </div>
        ),
        footer: "",
      },
    ]
  }, [])
  const getAction = async () => {
    let _res = await StaffService.getAction({
      FormID: 297,
    })
    setPermision(_res.data || [])
  }
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    let res = await ProjectService.getBangGia(_filter)
    setData(res.data || [])
    setLoading(false)
  }
  useEffect(() => {
    ;(async () => {
      await filter(filterCondition)
      // await getAction();
    })()
  }, [])
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0
      ? {
          background: "#f6f8fb",
        }
      : null
  }
  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.PageIndex = v
        break
      case "itemPerPage":
        _filter.PageIndex = 1
        _filter.PageSize = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bảng giá sản phẩm | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                {/* {permission.some(v=>v.FeatureID === 1) &&  */}
                <Button
                  label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  // onClick={()=>refDetail.current.create()}
                />
                {/* } */}
              </div>
            )}
            right={() => (
              <BangGia_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            )}
          />
          <ToolkitProvider
            keyField="id"
            data={data}
            columns={Columns()}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                {/* <Row> */}
                {/* <Col xl="12"> */}
                <LoadingBar loading={loading} />
                <div className="table-responsive table-scrollable shadow-2">
                  <BootstrapTable
                    keyField="id"
                    responsive
                    rowStyle={rowStyle}
                    bordered={true}
                    // striped={false}
                    defaultSorted={defaultSorted}
                    // selectRow={selectRow}
                    classes={
                      "table align-middle  table-nowrap table-check table-hover table-fixed "
                    }
                    headerWrapperClasses={"table-light"}
                    {...toolkitProps.baseProps}
                  />
                </div>
                {/* </Col> */}
                {/* </Row> */}
                {/* <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex"> */}
                <div className="flex align-items-center mt-2">
                  <div className="d-inline">
                    <select
                      className="form-select"
                      value={filterCondition.PageSize}
                      onChange={e =>
                        onChangeFilter("itemPerPage", Number(e.target.value))
                      }
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </select>
                  </div>
                  <Col className="pagination pagination-rounded justify-content-end inner-custom-pagination p-0">
                    <Pagination
                      totalPage={Math.ceil(
                        data[0]?.totalRows / filterCondition.PageSize
                      )}
                      changePage={i => onChangeFilter("page", i)}
                    />
                  </Col>
                </div>
                {/* </Col>
                </Row> */}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

BangGia.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(BangGia))
