import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react"
import {
  Row,
  Col,
  Drawer,
  notification,
  Tag,
  Flex,
  Image,
  Table,
  Button,
} from "antd"
import _ from "lodash"
import { Tabs } from "antd"
import { Format_Date, Format_Datetime } from "pages/Utility/common"
import { HopDongService } from "services/Hopdong"
//   import { YeuCauService } from "services/YeuCauService";
//   import FormDetail from "./FormDetail";

const { TabPane } = Tabs
const FormDetail = (props, ref) => {
  const refDetail = useRef(null)
  const { onAfterSubmit, options } = props
  const [api, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState([])
  const [nhatky, setNhatky] = useState([])
  const [dataHistory, setDataHistory] = useState([])
  const [isStart, setIsStart] = useState(false)
  const [timeLock, setTimeLock] = useState(0)

  const loadChamSoc = async linkId => {
    // let _res = await YeuCauService.getImages({ ID: linkId })
    // setImages(_res.data ?? [])
    let _resData = await HopDongService.DanhSachLookCan.detailLockCan({
      ID: linkId,
    })
    setData(_resData.data ?? [])
    setTimeLock(_resData.data?.thoiGianConLai)
    _resData.data?.thoiGianConLai > 0 && setIsStart(true)
    let _resPH = await HopDongService.DanhSachLookCan.historyLockCan({
      MaSP: _resData?.data?.maSP,
    })
    setNhatky(_resPH.data ?? [])
  }

  useEffect(() => {
    let _time = null
    if (isStart === true) {
      _time = setInterval(() => {
        setTimeLock(pre => {
          pre < 0 && setIsStart(false)
          return pre - 1
        })
      }, 1000)
    }
    return () => clearInterval(_time)
  }, [isStart])

  const getValueTime = () => {
    let _ss = Math.floor(timeLock % 60)
    let _mm = Math.floor((timeLock % (60 * 60)) / 60)
    return `${_mm < 10 ? "0" + _mm : _mm}:${_ss < 10 ? "0" + _ss : _ss}`
  }
  useImperativeHandle(ref, () => ({
    show: async linkId => {
      setOpen(true)
      loadChamSoc(linkId)
      // setDataHistory(item);
    },
  }))
  const onClose = () => {
    setOpen(false)
    setData({})
    setIsStart(false)
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    switch (p) {
      default:
        _data[p] = v
        break
    }
    setData(_data)
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const afterOnSubmit = async () => {
    let _resPH = await YeuCauService.getPhanHoi({ ID: data?.ID })
    setNhatky(_resPH.data ?? [])
  }
  const onSubmit = async close => {
    setLoading(true)
    let _data = _.cloneDeep(data)
    let _res = await YeuCauService.addPhanHoi(_data)
    setLoading(false)
    if (_res.status === 2000) {
      setData({ YeuCauID: _data.YeuCauID })
      // loadChamSoc(_data.YeuCauID);
      onAfterSubmit()
      openNotification("success", "topRight", `Thêm lịch sử thành công!`)
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }
  const columns = [
    {
      title: "Ngày lock",
      dataIndex: "NgayLock",
      render: item => (item ? Format_Datetime(item) : ""),
    },
    {
      title: "Trạng thái",
      dataIndex: "isLock",
      render: item => (
        <Tag style={{ color: "#333" }} color={item ? "green" : "red"}>
          {item ? "Unlock" : "Lock"}
        </Tag>
      ),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Sàn giao dịch",
      dataIndex: "TenSan",
      // render: (item) =>
      //   options?.NhanVien?.find((v) => v.MaNV === item)?.HoTenNV,
      // width: "150px",
    },
  ]

  const ThongTinChung = () => {
    return (
      <Row gutter={[24, 5]}>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Dự án</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>{data?.tenDA}</p>
        </Col>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Mã sản phẩm</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>
            {data?.kyHieu}
          </p>
        </Col>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Nhân viên</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>
            {data?.nhanVienNhap}
          </p>
        </Col>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Sàn</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>{data?.tenCT}</p>
        </Col>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Ngày lock</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>
            <p style={{ fontWeight: "500", padding: "8px 0px" }}>
              {Format_Datetime(data?.ngayLock)}
            </p>
          </p>
        </Col>
        <Col span={4} className="gutter-row" style={{ background: "#dadee3" }}>
          <p style={{ padding: "8px 0px" }}>Thời gian còn lại</p>
        </Col>
        <Col span={8} style={{ border: "0.5px solid #dadee3" }}>
          <p style={{ fontWeight: "500", padding: "8px 0px" }}>
            {timeLock > 0 ? (
              <p style={{ fontSize: "18px", color: "red" }}>
                {" "}
                {getValueTime()}
              </p>
            ) : (
              <p style={{ fontWeight: "600", color: "red" }}>Hết hạn</p>
            )}
          </p>
        </Col>
        <Col style={{ margin: "20px 0" }} span={24}>
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {images?.map(item => (
              <Image
                // width={140}
                style={{ paddingRight: 10, height: "100px", width: "auto" }}
                src={item.DuongDan}
              />
            ))}
          </Image.PreviewGroup>
        </Col>
        <Col span={24}>
          {/* <Divider orientation="left">Lịch sử</Divider> */}
          <Flex
            style={{ paddingBottom: "10px" }}
            align="center"
            justify="space-between"
          >
            <p
              style={{
                fontWeight: "bold",
                marginLeft: "-11px",
                // paddingBottom: "20px",
              }}
            >
              Lịch sử lock
            </p>
            <Button onClick={() => refDetail.current.show(data)}>Xử lý</Button>
          </Flex>
          <Table
            style={{ height: 100 }}
            loading={loading}
            dataSource={nhatky}
            columns={columns}
            size="small"
          />
        </Col>
      </Row>
    )
  }
  const HinhAnh = () => {
    return (
      <div>
        <h1>Hình ảnh</h1>
      </div>
    )
  }

  return (
    <>
      <Drawer
        title="Chi tiết"
        width={920}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Flex>
            <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
              {options?.ToaNha?.find(v => v.MaTN === data?.MaTN)?.TenTN}
            </p>
            {/* <Tag
              color={
                options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.Color
              }
            >
              {options?.TrangThai?.find((v) => v.MaTT === data?.MaTT)?.TenTT}
            </Tag> */}
            {/* <Button
              loading={loading}
              onClick={() => onSubmit(true)}
              type="primary"
            >
              Lưu & đóng
            </Button> */}
          </Flex>
        }
      >
        {contextHolder}

        {ThongTinChung()}
      </Drawer>

      {/* <FormDetail
          ref={refDetail}
          onAfterSubmit={afterOnSubmit}
          options={{
            NhanVien: options?.NhanVien,
            TrangThai: options?.TrangThai,
            onOffHistory: true,
          }}
        /> */}
    </>
  )
}
export default forwardRef(FormDetail)
