import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import { TabView, TabPanel } from "primereact/tabview"
import { Card, CardBody } from "reactstrap"
import { ProjectService } from "services/Project"
import BootstrapTable from "react-bootstrap-table-next"
import { Button } from "primereact/button"
import { AgencyService } from "services/Agency"
import Category_Detail from "./Category_Detail"
import Category_ByKhu_Detail from "./Category_ByKhu_Detail"
import CommonFunction from "components/CommonFunction"
function Category_ByKhu(props, ref) {
  const { maDA } = props
  const [show, setShow] = useState(false)
  const refDetail = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const [data, setData] = useState({})
  const [tableData, setTableData] = useState([])
  useImperativeHandle(ref, () => ({
    view: async _item => {
      await filter(0, _item.MaKhu)
      setData(_item)
      setShow(true)
    },
  }))
  const cancel = () => {
    setShow(false)
    setActiveTab(0)
    setData({})
  }
  const filter = async (tab, MaKhu) => {
    let _data = _.cloneDeep(tableData)
    switch (tab) {
      case 0: // tầng
        let _resTang = await AgencyService.ForSale.getTang({
          MaKhu: data.MaKhu || MaKhu,
        })
        _data = _resTang?.data
        break
      case 1: // vị trí
        let _resViTri = await AgencyService.ForSale.getViTri({
          MaKhu: data.MaKhu || MaKhu,
        })
        _data = _resViTri?.data
        break
      case 2: // loại căn hộ
        let _resLoaiCH = await AgencyService.Categories.getLoaiCH({
          MaKhu: data.MaKhu || MaKhu,
        })
        _data = _resLoaiCH?.data
        break
      default:
        break
    }
    setTableData(_data)
  }
  const renderTang = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message: "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept: async () => {
          let _res = await AgencyService.Categories.removeTang({
            maTang: data.MaTang,
          })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        },
      })
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenTang",
        text: "Tên tầng",
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add(
                  {
                    tenTang: row.TenTang,
                    maTang: row.MaTang,
                    maKhu: data.MaKhu,
                  },
                  activeTab
                )
              }
            />
            <Button
              className="p-button-success p-button-text text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="MaTang"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderViTri = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message: "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept: async () => {
          let _res = await AgencyService.Categories.removeViTri({
            maVT: data.ID,
          })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        },
      })
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenVT",
        text: "Tên vị trí",
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add(
                  {
                    tenVT: row.TenVT,
                    iD: row.ID,
                    maKhu: data.MaKhu,
                  },
                  activeTab
                )
              }
            />
            <Button
              className="p-button-success p-button-text text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="ID"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderLoaiCanHo = () => {
    const onDelete = async data => {
      CommonFunction.confirmRemove({
        message: "Bạn có chắc muốn xóa bản ghi?",
        header: "Xác nhận",
        accept: async () => {
          let _res = await AgencyService.Categories.removeLoaiCH({
            iD: data.ID,
          })
          if (_res.status === 2000) {
            await filter(activeTab)
          }
        },
      })
    }
    const columns = [
      {
        text: "STT",
        formatter: (cellContent, row, i) => {
          return <span>{i + 1}</span>
        },
        style: {
          width: "20px",
        },
      },
      {
        dataField: "TenLoaiMau",
        text: "Tên loại căn hộ",
      },
      {
        style: {
          width: "30px",
        },
        formatter: (cellContent, row) => (
          <>
            <Button
              className="p-button-success p-button-text ps-3 text-warning"
              icon="pi pi-pencil"
              onClick={() =>
                refDetail.current.add(
                  {
                    tenLoaiMau: row.TenLoaiMau,
                    iD: row.ID,
                    maKhu: data.MaKhu,
                    maDA: maDA,
                  },
                  activeTab
                )
              }
            />
            <Button
              className="p-button-success p-button-text ps-3 text-danger"
              icon="pi pi-trash"
              onClick={() => onDelete(row)}
            />
          </>
        ),
      },
    ]
    return (
      <BootstrapTable
        data={tableData === "[]" ? new Array([]) : tableData}
        keyField="ID"
        responsive
        bordered={true}
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const afterSubmit = async () => {
    await filter(activeTab)
  }
  try {
    return (
      <Dialog
        visible={show}
        header="Danh mục theo khu"
        style={{ width: "40%", height: "70%" }}
        className={`relative ${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        onHide={() => cancel()}
        maximizable
      >
        <div
          style={{
            height: "35px",
            background: "#ffffff",
            position: "absolute",
            top: 39,
            width: "100%",
            zIndex: 4,
          }}
          className="shadow-2 rounded flex align-items-center"
        >
          <Button
            label="Thêm"
            icon="pi pi-plus"
            className="p-button-success p-button-text"
            onClick={() =>
              refDetail.current.add(
                { maKhu: data?.MaKhu, maDA: maDA },
                activeTab
              )
            }
          />
        </div>
        <Card className="mb-0 mt-4">
          <CardBody>
            <TabView
              activeIndex={activeTab}
              onTabChange={e => {
                setActiveTab(e.index)
                filter(e.index)
              }}
            >
              <TabPanel header="Tầng">
                <div className="table-responsive">{renderTang()}</div>
              </TabPanel>
              <TabPanel header="Vị trí">
                <div className="table-responsive">{renderViTri()}</div>
              </TabPanel>
              <TabPanel header="Loại căn hộ">
                <div className="table-responsive">{renderLoaiCanHo()}</div>
              </TabPanel>
            </TabView>
          </CardBody>
        </Card>
        <Category_ByKhu_Detail ref={refDetail} afterSubmit={afterSubmit} />
      </Dialog>
    )
  } catch (error) {}
}
Category_ByKhu = forwardRef(Category_ByKhu)
export default Category_ByKhu
