import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import VayPieChart from "./VayPieChart"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
} from "../../common/common"
import Slider from "react-rangeslider"
import { Col, Container, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { getDuAn } from "../../store/actions"
// import NumericFormat from "react-number-format"
const TinhLaiNganHang = (tienVay, thoiHan, laiSuat) => {
  let arr = []
  let date = new Date()
  date.setMonth(date.getMonth() - 1)
  for (let i = 0; i <= thoiHan * 12; i++) {
    let tienGoc = i === 0 ? 0 : tienVay / (thoiHan * 12)
    let tienLai = i === 0 ? 0 : (arr[i - 1].GocConLai * laiSuat) / 100 / 12
    let obj = {
      STT: i,
      KyTraNo: date.setMonth(date.getMonth() + 1),
      GocConLai: tienVay,
      Goc: tienGoc,
      Lai: tienLai,
      GocCongLai: tienLai + tienGoc,
    }
    arr.push(obj)
  }
  return arr
}
const DetailSanPham = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const location = useLocation()
  const { tinTuc, duAn, isLoadFilter } = useSelector(state => ({
    tinTuc: state.TinTuc.tinTuc,
    duAn: state.Agency.duAn,
    isLoadFilter: state.HopDong.isLoadFilter,
  }))
  const [tyLeVay, setTyLeVay] = useState(40)
  const [thoiHanVay, setThoiHanVay] = useState(10)
  const [laiSuat, setLaiSuat] = useState(6)
  const [tienVayNhap, setTienVayNhap] = useState("")
  const [data, setData] = useState([])
  const [tien, setTien] = useState({})
  const ptRef = useRef(40)

  const Columns = useCallback(() => {
    return [
      {
        dataField: "STT",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.STT}
          </Link>
        ),
        footer: (cellContent, row) => cellContent.length - 1 + " tháng",
      },
      {
        dataField: "KyTraNo",
        text: "Kỳ trả nợ",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.KyTraNo),
        footer: "",
      },
      {
        dataField: "GocConLai",
        text: "Gốc còn lại",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.GocConLai),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "Goc",
        text: "Gốc",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.Goc),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "Lai",
        text: "Lãi",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.Lai),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "GocCongLai",
        text: "Tiền gốc + lãi",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.GocCongLai),
        footer: (columnData, column, columnIndex) =>
          Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
    ]
  }, [])
  useEffect(() => {
    dispatch(getDuAn())
  }, [])
  useState(() => {
    let _tongTien =
      location.type === 1
        ? location.state?.tongGiaGomPBT
        : location.state?.TongGiaTriHDMB
    let datachange = TinhLaiNganHang(
      (_tongTien * tyLeVay) / 100,
      thoiHanVay,
      laiSuat
    )
    setData(datachange)
    setTienVayNhap((_tongTien * tyLeVay) / 100)
    setTien({
      tienTraTruoc: _tongTien - (_tongTien * tyLeVay) / 100,
      tienVay: (_tongTien * tyLeVay) / 100,
      tienLai: datachange.reduce((acc, item) => acc + item.Lai, 0),
    })
  }, [location.state])
  const onChangePT = (value, type) => {
    let _tongTien =
      location.type === 1
        ? location.state?.tongGiaGomPBT
        : location.state?.TongGiaTriHDMB
    setTyLeVay(value)
    // setTienVayNhap((_tongTien * value) / 100)
    setTien({
      tienTraTruoc: _tongTien - (_tongTien * value) / 100,
      tienVay: (_tongTien * value) / 100,
      tienLai: 0,
    })
  }
  const onChangeTienVay = value => {
    let _tongTien =
      location.type === 1
        ? location.state?.tongGiaGomPBT
        : location.state?.TongGiaTriHDMB
    setTienVayNhap(value.formattedValue)
    setTyLeVay((value.floatValue / _tongTien) * 100)
    setTien({
      tienTraTruoc: _tongTien - value.floatValue,
      tienVay: value.floatValue,
      tienLai: 0,
    })
  }
  const onChangeTHVay = value => {
    let _tongTien =
      location.type === 1
        ? location.state?.tongGiaGomPBT
        : location.state?.TongGiaTriHDMB
    setThoiHanVay(value)
    setTien({
      tienVay: (_tongTien * tyLeVay) / 100,
      tienTraTruoc: _tongTien - (_tongTien * tyLeVay) / 100,
      tienLai: 0,
    })
  }
  const onChangeLaiSuat = value => {
    let _tongTien =
      location.type === 1
        ? location.state?.tongGiaGomPBT
        : location.state?.TongGiaTriHDMB
    setLaiSuat(value)
    setTien({
      tienVay: (_tongTien * tyLeVay) / 100,
      tienTraTruoc: _tongTien - (_tongTien * tyLeVay) / 100,
      tienLai: 0,
    })
  }
  useEffect(() => {
    let time
    if (!time) {
      time = setTimeout(() => {
        let datachange = TinhLaiNganHang(tien?.tienVay, thoiHanVay, laiSuat)
        setData(datachange)
        setTien({
          ...tien,
          tienLai: datachange.reduce((acc, item) => acc + item.Lai, 0),
        })
      }, [500])
    }
    return () => {
      clearTimeout(time)
    }
  }, [tienVayNhap, tyLeVay, thoiHanVay, laiSuat])
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  console.log(location.state)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Chi tiết| Beesky</title>
        </MetaTags>
        <Container fluid>
          {/* <Breadcrumbs
            title="Trang chủ"
            route="/home"
            breadcrumbItem="Cần bán"
          />
           */}
          <Row className="pb-3">
            <Col xs="9">
              <Row>
                <h4>Vay ngân hàng</h4>

                <Col md={5}>
                  <div>
                    <Row>
                      <label
                        htmlFor="example-text-input"
                        className="col-md-4 col-form-label"
                      >
                        Tỷ lệ vay(%)
                      </label>
                      <div className="col-md-5" style={{ paddingRight: "0px",paddingLeft: "0px"}}>
                        {/* <NumericFormat
                          className="form-control"
                          thousandSeparator={true}
                          value={tienVayNhap}
                          onValueChange={e => onChangeTienVay(e)}
                          format={e => Format_Currency(e)}
                        /> */}
                      </div>
                      <div
                        className="col-md-3 pl-0"
                        style={{ paddingLeft: "0px" }}
                      >
                        <input
                          className="form-control"
                          type="number"
                          value={tyLeVay}
                          onChange={value => {
                            onChangePT(value.target.value)
                          }}
                          defaultValue={tyLeVay}
                        />
                      </div>
                    </Row>
                    <Slider
                      value={tyLeVay}
                      min={1}
                      max={100}
                      orientation="horizontal"
                      onChange={value => {
                        onChangePT(value)
                      }}
                    />
                  </div>
                  <div>
                    <Row>
                      <label
                        htmlFor="example-text-input"
                        className="col-md-9 col-form-label"
                      >
                        Thời hạn vay(năm)
                      </label>
                      <div className="col-md-3">
                        <input
                          className="form-control"
                          type="number"
                          value={thoiHanVay}
                          defaultValue={thoiHanVay}
                          onChange={value => {
                            onChangeTHVay(value.target.value)
                          }}
                        />
                      </div>
                    </Row>
                    <Slider
                      value={thoiHanVay}
                      min={1}
                      max={50}
                      orientation="horizontal"
                      onChange={value => {
                        onChangeTHVay(value)
                      }}
                    />
                  </div>
                  <Row>
                    <label
                      htmlFor="example-text-input"
                      className="col-md-9 col-form-label"
                    >
                      Lãi suất
                    </label>
                    <div className="col-md-3">
                      <input
                        className="form-control"
                        type="number"
                        value={laiSuat}
                        onChange={value => {
                          onChangeLaiSuat(value.target.value)
                        }}
                        defaultValue={laiSuat}
                      />
                    </div>
                  </Row>
                </Col>

                <Col md={5}>
                  <VayPieChart
                    series={[
                      (tien?.tienTraTruoc /
                        (tien?.tienTraTruoc + tien?.tienVay + tien?.tienLai)) *
                        100,
                      (tien?.tienVay /
                        (tien?.tienTraTruoc + tien?.tienVay + tien?.tienLai)) *
                        100,
                      (tien?.tienLai /
                        (tien?.tienTraTruoc + tien?.tienVay + tien?.tienLai)) *
                        100,
                    ]}
                  />
                </Col>
                <Col md={2}>
                  <p className="m-0 pt-3">Tiền trả trước</p>
                  <p className="fw-bold" style={{ color: "#34c38f" }}>
                    {Format_Currency(tien?.tienTraTruoc)}
                  </p>
                  <p className="m-0">Tiền vay ngân hàng</p>
                  <p className="fw-bold" style={{ color: "#556ee6" }}>
                    {Format_Currency(tien?.tienVay)}
                  </p>
                  <p className="m-0">Tiền lãi vay</p>
                  <p className="fw-bold" style={{ color: "#f1b44c" }}>
                    {Format_Currency(tien?.tienLai)}
                  </p>
                  <p className="m-0">Tổng tiền</p>
                  <p className="fw-bold" style={{ color: "#f46a6a" }}>
                    {Format_Currency(tien?.tienLai + tien?.tienVay)}
                  </p>
                </Col>
              </Row>
              {/* <Card>
                <CardBody> */}
              <ToolkitProvider
                keyField="maTin"
                data={data === "[]" ? new Array([]) : data}
                columns={Columns()}
                bootstrap4
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive table-scrollable">
                          <BootstrapTable
                            keyField="maTin"
                            responsive
                            bordered={true}
                            striped={false}
                            defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={
                              "table align-middle  table-nowrap table-check table-hover table-fixed "
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
              {/* </CardBody>
              </Card> */}
            </Col>
            <Col xs="3">
              <div className="rounded border-0 shadow-lg bg-white">
                <div>
                  <img
                    className="rounded-top"
                    style={{ width: "100%" }}
                    src="https://quanlykinhdoanh.com.vn/httpdocs/Upload/REM/DEMO41/documents/sanpham/2021/12/17/banner-15132841859786129866.jpg"
                  />
                  <h5 className="text-center fw-bold mt-2">
                    {location.type === 2
                      ? location.state.TenDA
                      : location.state.tenDA}
                  </h5>
                </div>
                <div className="row p-3" style={{ lineHeight: "200%" }}>
                  <Col lg="7">Mã sản phẩm</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? location.state.KyHieu
                      : location.state?.maSanPham}
                  </Col>
                  <Col lg="7">Hướng</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? location.state?.TenPhuongHuong
                      : location.state?.huongBC}
                  </Col>
                  <Col lg="7">Tầng</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? location.state.Tang
                      : location.state?.tang}
                  </Col>
                  <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                  <Col lg="7">Diện tích thông thủy</Col>
                  <Col className="fw-bold" lg="5">
                    {location.type === 2
                      ? location.state?.DTThongThuy
                      : location.state?.dienTichTT}
                    m2
                  </Col>
                  <Col lg="7">Đơn giá thông thủy</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? Format_Currency(location.state?.DonGiaThongThuy)
                      : Format_Currency(location.state?.donGiaTT)}
                  </Col>
                  <Col lg="7">Thành tiền gổm VAT</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? Format_Currency(location.state?.TongGiaGomVAT)
                      : Format_Currency(location.state?.tongGiaGomVAT)}
                  </Col>
                  <Col lg="7">Phí bảo trì</Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? Format_Currency(location.state?.PhiBaoTri)
                      : Format_Currency(location.state?.tienPBT)}
                  </Col>

                  <hr className=" mt-3 bg-danger border-2 border-top border-danger" />
                  <Col lg="7" className="fw-bold">
                    Tổng giá gồm PBT
                  </Col>
                  <Col lg="5" className="fw-bold">
                    {location.type === 2
                      ? Format_Currency(location.state?.TongGiaTriHDMB)
                      : Format_Currency(location.state?.tongGiaGomPBT)}
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DetailSanPham.propTypes = {
  customers: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}

export default React.memo(withRouter(withTranslation()(DetailSanPham)))

//NOTE THÔNG TIN MÃ
// lacation.type
// + 1 là xem từ chi tiết
// + 2 là xem từ bảng hàng
