import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, useHistory,Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { withTranslation } from "react-i18next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import DeleteModal from "../../components/Common/DeleteModal"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getPermision as onGetPermision,
  deletePermision,
} from "../../store/actions"
import FormModal from "./FormModal"
import Permision_Detail from "./Permision_Detail"
import FormAdd from "./FormAdd"
import "./index.scss"

const PerIndex = props => {
  const history = useHistory();
  const refDetail = useRef(null);
  const dispatch = useDispatch()
  const { permission } = useSelector(state => ({
    permission: state.Permission.permission,
  }))
  const selectRow = {
    hideSelectColumn: true,
  }
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState({})
  const [staffForm, setStaffForm] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: permission.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search
  const StaffColumns = toggleModal => [
    {
      dataField: "stt",
      text: "STT",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.stt}
        </Link>
      ),
    },
    {
      dataField: "perName",
      text: `${props.t("Permission Name")}`,
      sort: true,
    },
    {
      dataField: "description",
      text: `${props.t("Description")}`,
      sort: true,
    },
    {
      dataField: "isAgent",
      text: `${props.t("Agent")}`,
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, item) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleStaffClick(item)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {props.t("Edit")}
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDelete(item.perID)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                {props.t("Delete")}
              </UncontrolledTooltip>
            </Link>
            {/* onclick để mở tab phân quyền */}
            {/* <Link
              to="#"
              className="text-primary"
              onClick={() => permissionClick(item.perID)}
              // onClick={() => refDetail.current.view(item)}
            > */}
            <i
              className="bx bx-alarm-exclamation font-size-18"
              id="pertooltip"
              onClick={() => refDetail.current.show(item.perID)}
            />
            <UncontrolledTooltip placement="top" target="pertooltip">
              {props.t("Permission")}
            </UncontrolledTooltip>
            {/* </Link> */}
          </div>
        </>
      ),
    },
  ]
  // const permissionClick=(e)=>{
  //   history.push("/permission/"+e);
  // }
  useEffect(() => {
    dispatch(onGetPermision())
  }, [])
  const toggle = () => {
    setModal(!modal)
  }
  const handleStaffClick = item => {
    setStaffForm({
      PerID: item.perID,
      PerName: item.perName,
      Description: item.description,
    })
    setIsEdit(true)
    toggle()
  }
  const toggleDelete = () => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
    })
  }
  const handleDelete = useCallback(id => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(id),
    })
  }, [])
  const onClickDelete = useCallback(id => {
    dispatch(deletePermision({ PerID: id }))
  }, [])

  const handleAddStaff = () => {
    setStaffForm([])
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const onAfterSubmit = () => {
    // filter();
  };
  return (
    <React.Fragment>
       <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <DeleteModal data={modalDelete} toggle={toggleDelete} />
      <FormModal
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        detail={staffForm}
      />
      <div className="page-content">
        <MetaTags>
          <title>Phân quyền | Beesky</title>
        </MetaTags>
        <Container fluid>
          
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="perID"
                    columns={StaffColumns(toggle)}
                    data={permission}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="perID"
                        data={permission}
                        columns={StaffColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleAddStaff}
                                  >
                                    <i className="mdi mdi-plus me-1" />

                                    {props.t("Add")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive table-scrollable">
                                  <BootstrapTable
                                    keyField="stt"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check table-fixed"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Permision_Detail ref={refDetail} /> */}
    </React.Fragment>
  )
}

PerIndex.propTypes = {
  // permission: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PerIndex))
