import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import DeleteModal from "../../components/Common/DeleteModal"
// import ListImageModal from "./ListImageModal"
// import DetailModal from "./DetailModal"
import Flatpickr from "react-flatpickr"
import { MultiSelect } from "react-multi-select-component"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
} from "../../common/common"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Image,
} from "reactstrap"
import Pagination from "../../components/Common/Pagination"
import { useSelector, useDispatch } from "react-redux"
import { getTinTuc, getDuAn, deleteTinTuc } from "../../store/actions"

const localization = {
  allItemsAreSelected: "Đã chọn tất cả.",
  clearSearch: "Clear Search",
  noOptions: "Không có dự án",
  search: "Tìm kiếm",
  selectAll: "Tất cả",
  selectAllFiltered: "Tất cả(Đã lọc)",
  selectSomeItems: "Dự án...",
}
const TinTucIndex = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tinTuc, duAn, isLoadFilter } = useSelector(state => ({
    tinTuc: state.TinTuc.tinTuc,
    duAn: state.Agency.duAn,
    isLoadFilter: state.HopDong.isLoadFilter,
  }))
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [modalImage, setModalImage] = useState(false)
  const [page, setPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(10)
  const [item, setItem] = useState([])
  const [textSearch, setTextSearch] = useState("")
  const [selectedDuAn, setSelectedDuAn] = useState([])
  const [optionDuAn, setOptionDuAn] = useState([])
  const [valueDate, setValueDate] = useState([
    new Date("JAN 01, 2020"),
    new Date("JAN 01, 2025"),
  ])
  const [dateFrom, setDateFrom] = useState("01/01/2020")
  const [dateTo, setDateTo] = useState("1/01/2025")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [maKhu, setMaKhu] = useState(0)
  const [maTT, setMaTT] = useState(0)
  const [maDuAn, setMaDuAn] = useState("")

  const [modalDetail, setModalDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState({})

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maTin !== row.maTin
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      {
        dataField: "maTin",
        text: "Xem",
        sort: true,
        formatter: (cellContent, row) => (
          <Link
            to="#"
            // onClick={() => toggleDetail(row)}
            className="text-body fw-bold"
          >
            <i className="mdi mdi-17px mdi-eye"></i>
          </Link>
        ),
      },
      {
        dataField: "tenDA",
        text: "Tên dự án",
        sort: true,
      },
      {
        dataField: "tenLoai",
        text: "Loại tin",
        sort: true,
      },
      {
        dataField: "viTri",
        text: "Vị trí",
        sort: true,
      },
      {
        dataField: "tieuDe",
        text: "Tiêu đề",
        sort: true,
      },
      {
        dataField: "ngayNhap",
        text: "Ngày nhập",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
      },
      {
        dataField: "ngaySua",
        text: "Ngày sửa",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngaySua),
      },
      {
        dataField: "nhanVienNhap",
        text: "Nhân viên nhập",
        sort: true,
      },
      {
        dataField: "nhanVienSua",
        text: "Nhân viên sửa",
        sort: true,
      },
      {
        dataField: "isView",
        text: "Hiển thị",
        sort: true,
        formatter: (cellContent, row) => (row.isView ? "Có" : "Không"),
      },
      {
        dataField: "isBanner",
        text: "Banner",
        sort: true,
        formatter: (cellContent, row) => (row.isBanner ? "Có" : "Không"),
      },
      {
        dataField: "imgIcon",
        text: "Hình ảnh",
        sort: true,
        formatter: (cellContent, row) => (
          <img src={row.imgIcon} style={{ width: "100px", height: "50px" }} />
        ),
      },
    ]
  }, [])
  useEffect(() => {
    dispatch(getDuAn())
  }, [])
  // useEffect(() => {
  //   dispatch(
  //     getTinTuc({
  //       TuNgay: dateFrom,
  //       DenNgay: dateTo,
  //       // DuAn: maDuAn,
  //       // MaTT: maTT,
  //       // MaKhu: maKhu,
  //       // inputSearch: textSearch,
  //       // Offset: page,
  //       // Limit: itemPerPage,
  //     })
  //   )
  //   setItem([])
  // }, [page, itemPerPage])

  const handleEdit = () => {
    // dispatch(editForSale({ ...item[0] }))
    history.push({
      pathname: "/them-sua-tin-tuc",
      state: item[0],
    })
    // history.push("/forsale/add")
  }
  const toggleDelete = () => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
    })
  }
  const toggleDetail = item => {
    setDataDetail({ ...item })
    setModalDetail(!modalDetail)
  }
  const toggleImage = () => {
    setModalImage(!modalImage)
  }
  const onClickXemAnh = () => {
    // dispatch(getImageForsale({ MaBC: item[0]?.maBC }))
    // toggleImage()
  }
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {
    const arrDelete = []
    itemDL?.map(i => {
      arrDelete.push(i.maTin)
    })
    // debugger
    if (arrDelete.length > 0) {
      dispatch(deleteTinTuc({ MaTin: arrDelete }))
    }
  }, [])

  const handleAdd = () => {
    // dispatch(editForSale(null))
    history.push({
      pathname: "/them-sua-tin-tuc",
      state: null,
    })
    // history.push("/news/add")
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const onClickchangePage = i => {
    setPage(i)
  }
  const onClickChangeItemPerPage = e => {
    setPage(1)
    setItemPerPage(Number(e.target.value))
  }
  const onChangeTextSearch = e => {
    setTextSearch(e.target.value)
  }
  useEffect(() => {
    let time
    if (!time) {
      time = setTimeout(() => {
        dispatch(
          getTinTuc({
            TuNgay: dateFrom,
            DenNgay: dateTo,
            // DuAn: maDuAn,
            // MaTT: maTT,
            // MaKhu: maKhu,
            // inputSearch: textSearch,
            // Offset: page,
            // Limit: itemPerPage,
          })
        )
        setItem([])
      }, [1000])
    }
    return () => {
      clearTimeout(time)
    }
  }, [textSearch])
  useEffect(() => {
    let arr = []
    if (duAn != "[]") {
      duAn?.map(item => {
        arr.push({
          label: item.TenDA,
          value: item.MaDA,
        })
      })
      setOptionDuAn(arr)
    }
  }, [duAn])

  const handleValidOrderSubmit = (e, values) => {
    let strMaDA = ","
    if (selectedDuAn.length === 0) strMaDA = ""
    selectedDuAn.map(item => {
      strMaDA += item.value + ","
    })
    setMaDuAn(strMaDA)
    dispatch(
      getTinTuc({
        TuNgay: dateFrom.toString(),
        DenNgay: dateTo.toString(),
        // DuAn: strMaDA,
        // MaTT: maTT,
        // MaKhu: Number(values.MaKhu) === "" ? 0 : Number(values.MaKhu),
        // inputSearch: textSearch,
        // Offset: 1,
        // Limit: 10,
      })
    )
  }
  const onChangeValueDate = e => {
    let dateFrom =
      e[0].getMonth() + 1 + "/" + e[0].getDate() + "/" + e[0].getFullYear()
    let dateTo =
      e[1]?.getMonth() + 1 + "/" + e[1]?.getDate() + "/" + e[1]?.getFullYear()
    setDateFrom(dateFrom)
    setDateTo(dateTo)
  }
  const duAnOnChange = v => {
    const vl = v
      .map(function (elem) {
        return elem.value
      })
      .join()
    setSelectedDuAn(v)
    dispatch(
      getKhuByListDA({
        MaDA: vl,
      })
    )
  }
  const khuOnChange = v => {
    setMaKhu(Number(v.target.value))
  }
  const statusOnChange = v => {
    setMaTT(v)
    dispatch(
      getTinTuc({
        TuNgay: dateFrom,
        DenNgay: dateTo,
        // DuAn: maDuAn,
        // MaTT: v,
        // MaKhu: maKhu,
        // inputSearch: textSearch,
        // Offset: page,
        // Limit: itemPerPage,
      })
    )
  }
  return (
    <React.Fragment>
      <DeleteModal data={modalDelete} toggle={toggleDelete} />
      {/* <DetailModal
        className="vh-100"
        isOpen={modalDetail}
        toggle={toggleDetail}
        data={dataDetail}
      /> */}
      {/* <ListImageModal isOpen={modalImage} toggle={toggleImage} data={item[0]} /> */}
      <div className="page-content">
        <MetaTags>
          <title>Tin tức| Beesky</title>
        </MetaTags>
        <Container fluid>
          {/* <Breadcrumbs
            title="Trang chủ"
            route="/home"
            breadcrumbItem="Cần bán"
          />
           */}
          <Row className="mb-1">
            <Col md="2">
              <Flatpickr
                className="form-control d-block"
                placeholder="Từ ngày đến ngày"
                value={valueDate}
                options={{
                  mode: "range",
                  dateFormat: "d-m-y",
                }}
                onChange={e => onChangeValueDate(e)}
              />
            </Col>
            <AvForm
              className="col-md-8"
              onValidSubmit={handleValidOrderSubmit}
              // row
            >
              <Row>
                {/* <Col md="2">
                  <AvField
                    onChange={v => khuOnChange(v)}
                    name="maDA"
                    type="select"
                    className="form-select"
                  >
                    <option value="">Dự án</option>
                    {duAn &&
                      duAn.map(item => {
                        return (
                          <option key={item.MaDA} value={Number(item.MaDA)}>
                            {item.TenDA}
                          </option>
                        )
                      })}
                  </AvField>
                </Col> */}
                <Col md="2">
                  {isLoadFilter === true ? (
                    <button type="button" className="btn btn-primary disabled">
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      Loading
                    </button>
                  ) : (
                    <Button color="primary" type="submit">
                      <i className="bx bx-reset me-1" />
                      Nạp
                    </Button>
                  )}
                </Col>
              </Row>
            </AvForm>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="10">
                      <div className="text-sm-start">
                        <Button
                          type="button"
                          color="success"
                          className="mb-2 me-2"
                          onClick={handleAdd}
                        >
                          <i className="mdi mdi-plus me-1" />

                          {props.t("Add")}
                        </Button>
                        {item.length === 1 && (
                          <Button
                            type="button"
                            color="primary"
                            className="mb-2 me-2"
                            onClick={handleEdit}
                          >
                            <i className="mdi mdi-pencil me-1" />

                            {props.t("Edit")}
                          </Button>
                        )}
                        {item.length >= 1 && (
                          <Button
                            type="button"
                            color="danger"
                            className="mb-2 me-2"
                            onClick={() => handleDelete(item)}
                          >
                            <i className="mdi mdi-delete me-1" />

                            {props.t("Delete")}
                          </Button>
                        )}
                        {/* {item.length >= 1 && (
                          <ButtonDropdown
                            className="mb-2 me-2"
                            isOpen={modalDropBtn}
                            toggle={() => toggleDropdownBtn()}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-info rounded"
                            >
                              Hành động <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {item.length === 1 && (
                                <DropdownItem onClick={() => onClickXemAnh()}>
                                  <i className="bx bx-search-alt me-1" />
                                  Xem ảnh
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </ButtonDropdown>
                        )} */}
                        {/* <Button
                          key={item.MaTT}
                          type="button"
                          color="success"
                          outline
                          style={{
                            borderColor: maTT === 0 ? "red" : "",
                            fontWeight: maTT === 0 ? "bold" : "",
                            color: "black",
                          }}
                          className="mb-2 me-2"
                          onClick={() => statusOnChange(0)}
                        >
                          {props.t("Tất cả")}
                        </Button> */}
                        {/* {listTrangThaiHD &&
                          listTrangThaiHD.map(item => {
                            return (
                              <Button
                                key={item.MaTT}
                                type="button"
                                // color="success"
                                outline
                                style={{
                                  backgroundColor: item.ColorWeb,
                                  borderColor:
                                    maTT === item.MaTT ? "red" : item.ColorWeb,
                                  fontWeight: maTT === item.MaTT ? "bold" : "",
                                  color: "black",
                                }}
                                className="mb-2 me-2"
                                onClick={() => statusOnChange(item.MaTT)}
                              >
                                {props.t(item.TenTT)}
                              </Button>
                            )
                          })} */}
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="ml-100 search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            id="staticEmail2"
                            placeholder="Nhập từ tìm kiếm"
                            onChange={onChangeTextSearch}
                            value={textSearch}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <ToolkitProvider
                    keyField="maTin"
                    data={tinTuc === "[]" ? new Array([]) : tinTuc}
                    columns={Columns()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive table-scrollable">
                              <BootstrapTable
                                keyField="maTin"
                                responsive
                                bordered={true}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle  table-nowrap table-check table-hover table-fixed "
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <select
                                className="form-select"
                                value={itemPerPage}
                                onChange={e => onClickChangeItemPerPage(e)}
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={1000}>1000</option>
                              </select>
                            </div>
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <Pagination
                                totalPage={Math.ceil(
                                  listDatCoc[0]?.TotalRows / itemPerPage
                                )}
                                changePage={onClickchangePage}
                              />
                            </Col>
                          </Col>
                        </Row> */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TinTucIndex.propTypes = {
  tinTuc: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(TinTucIndex))
