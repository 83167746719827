import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {  handleValidDate } from "../../../common/common"
import { Button } from "primereact/button"
import LoadingBar from "components/LoadingBar"
import { Col, Container } from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import Category_BangGia_Filter from "./Category_BangGia_Filter"
import ToolBar from "components/Toolbar"
import { ProjectService } from "services/Project"
import { StaffService } from "services/Staff"
const Cagegory_BangGia = props => {
  const history = useHistory()
  let preyear = (new Date()).setFullYear(new Date().getFullYear() -5)
  const defaultCondition = {
    TuNgay: new Date(preyear),
    DenNgay: new Date(),
    inputSearch: "",
    DuAn: [],
    PageIndex: 1,
    PageSize: 20,
  }
  const refDetail = useRef(null)
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [permission, setPermision] = useState([])

  const detailClick = data => {
    history.push({
      pathname: "/sanpham-chitiet",
      type: 1,
      state: data,
    })
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => detailClick(row)}
            className="text-body fw-bold"
          >
            {row.stt}
          </div>
        ),
      },
      {
        dataField: "tenDA",
        text: "Dự án",
        sort: true,
      },
      {
        dataField: "tenDot",
        text: "Tên đợt",
        sort: true,
      },
      {
        text: "Khóa",
        sort: true,
        formatter: (cellContent, row) => <span>{row.khoa ? 'Khóa' : 'Không khóa'}</span>,
      },
      {
        dataField: "hoTen",
        text: "Khách hàng",
        sort: true,
      },
      {
        dataField: "nguoiNhap",
        text: "Người tạo",
        sort: true,
      },
      {
        text: "Ngày tạo",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
      },
      {
        dataField: "nguoiSua",
        text: "Người sửa",
        sort: true,
      },
      {
        text: "Ngày sửa",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngaySua),
      },
      {
        style: {
          backgroundColor: "white",
          position: "sticky",
          right: 0,
          paddingLeft: "0px",
        },
        headerStyle: {
          backgroundColor: "rgb(236, 240, 247)",
          position: "sticky",
          right: 0,
        },
        formatter: (cellContent, row) => (
          <div
            className="d-flex gap-3"
            style={{ boxShadow: "-5px 0 5px -5px #333" }}
          >
            <Link
              to="#"
              className="text-warning ps-3"
              // onClick={() => refDetail.current.update(row)}
            >
              <i className="pi pi-pencil" />
            </Link>
            <Link to="#" className="text-danger">
              <i className="pi pi-trash" />
            </Link>
          </div>
        ),
      },
    ]
  }, [])
  const getAction = async () => {
    let _res = await StaffService.getAction({
      FormID: 297,
    })
    setPermision(_res.data || [])
  }
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    _filter.DuAn = _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ProjectService.getCategoryBangGia(_filter)
    setData(res.data || [])
    setLoading(false)
  }
  useEffect(() => {
    (async () => {
      await filter(filterCondition)
      // await getAction()
    })()
  }, [])
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0
      ? {
          background: "#f6f8fb",
        }
      : null
  }
  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.PageIndex = v
        break
      case "itemPerPage":
        _filter.PageIndex = 1
        _filter.PageSize = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Danh mục bảng giá | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                {/* {permission.some(v => v.FeatureID === 1) && ( */}
                  <Button
                    label="Thêm"
                    icon="mdi mdi-plus"
                    className="p-button-outlined p-button-success mr-1"
                    // onClick={() => refDetail.current.create()}
                  />
                {/* // )} */}
              </div>
            )}
            right={() => (
              <Category_BangGia_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            )}
          />
          <ToolkitProvider
            keyField="maSP"
            data={data}
            columns={Columns()}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                {/* <Row> */}
                {/* <Col xl="12"> */}
                <LoadingBar loading={loading} />
                <div className="table-responsive table-scrollable shadow-2">
                  <BootstrapTable
                    keyField="maSP"
                    responsive
                    rowStyle={rowStyle}
                    bordered={true}
                    // striped={false}
                    defaultSorted={defaultSorted}
                    // selectRow={selectRow}
                    classes={
                      "table align-middle  table-nowrap table-check table-hover table-fixed "
                    }
                    headerWrapperClasses={"table-light"}
                    {...toolkitProps.baseProps}
                  />
                </div>
                {/* </Col> */}
                {/* </Row> */}
                {/* <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex"> */}
                <div className="flex align-items-center mt-2">
                  <div className="d-inline">
                    <select
                      className="form-select"
                      value={filterCondition.PageSize}
                      onChange={e =>
                        onChangeFilter("itemPerPage", Number(e.target.value))
                      }
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </select>
                  </div>
                  <Col className="pagination pagination-rounded justify-content-end inner-custom-pagination p-0">
                    <Pagination
                      totalPage={Math.ceil(
                        data[0]?.totalRows / filterCondition.PageSize
                      )}
                      changePage={i => onChangeFilter("page", i)}
                    />
                  </Col>
                </div>
                {/* </Col>
                </Row> */}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

Cagegory_BangGia.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Cagegory_BangGia))
