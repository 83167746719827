import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import _ from "lodash";
import Contract_Filter from "./Debt_TheoDot_Filter"
import {
  handleValidDate,
  Format_Currency
} from "../../common/common"
import {
  Button,
  Col,
  Container,
  Row,
} from "reactstrap"
import Pagination from "../../components/Common/Pagination"
import { DebtService } from "services/Debt"
import { InputText } from "primereact/inputtext"
import Contract_Detail from './Debt_TheoDot_Detail'
import Toolbar from '../../components/Toolbar';
import LoadingBar from 'components/LoadingBar'

const Debt_TheoDot = props => {
  const refDetail = useRef(null);
  const [loading,setLoading] = useState(false);
  const defaultCondition = {
    TuNgay: "2019-01-04",
    DenNgay: "2024-10-13",
    inputSearch: "",
    DuAn: [],
    Offset: 1,
    Limit: 20
  }
  const [data, setData] = useState([]);
  const [filterCondition, setFilterCondition] = useState(defaultCondition);
  const filter = async (filter) => {
    setLoading(true);
    let _filter = _.cloneDeep(filter);
    _filter.DuAn = _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await DebtService.filterTheoDot(_filter)
    setData(res?.data || [])
    setLoading(false);
  }
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const afterChangeFilter = (_filter) => {
    setFilterCondition(_filter);
    filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "search":
        _filter.inputSearch = v;
        break;
      case "page":
        _filter.Offset = v;
        break;
      case "itemPerPage":
        _filter.Offset = 1;
        _filter.Limit = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter)
  }
  const [item, setItem] = useState([])
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maPGC !== row.maPGC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      {
        dataField: "ngayKy",
        text: "Ngày ký",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayKy),
      },
      {
        dataField: "soHDMB",
        text: "Số HDMB",
        sort: true,
      },
      {
        dataField: "kyHieu",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "hoTenKH",
        text: "Tên KH",
        sort: true,
      },
      {
        dataField: "soCMND",
        text: "Số CMND",
        sort: true,
      },
      {
        dataField: "diDong",
        text: "Di động",
        sort: true,
      },
      {
        dataField: "dotTT",
        text: "Đợt thanh toán",
        sort: true,
      },
      {
        dataField: "soTien",
        text: "Số tiền",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.soTien),
      },
      {
        dataField: "daThu",
        text: "Đã thu",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.daThu),
      },
      {
        dataField: "conNo",
        text: "Còn lại",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.conNo),
      },
      {
        dataField: "phiBT",
        text: "Phí bảo trì",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.phiBT),
      },
      {
        dataField: "daThuPBT",
        text: "Đã thu PBT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.daThuPBT),
      },
      {
        dataField: "conNoPBT",
        text: "Còn lại PBT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.conNoPBT),
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
    ]
  }, [])
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0 ? {
      background: '#f6f8fb'
    } : null
  };
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const onClickAdd = () => {
    refDetail.current.create();
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title>Hợp đồng| Beesky</title>
        </MetaTags>
        <Container fluid style={{
          // maxHeight: window.outerHeight -220
        }}>
          <Toolbar
            left={() => {
              return <Button
                type="button"
                color="success"
                onClick={onClickAdd}
              >
                <i className="pi pi-plus" style={{ 'fontSize': '1em' }} />

                {props.t("Tạo mới")}
              </Button>
            }}
            right={() => <Contract_Filter filterCondition={filterCondition} afterChangeFilter={afterChangeFilter} />}
          />
          <Row>
            <Col xs="12" >
              <ToolkitProvider
                keyField="stt"
                data={data === "[]" ? new Array([]) : data}
                columns={Columns()}
                bootstrap4
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                      <LoadingBar loading={loading} />
                        <div className="table-responsive table-scrollable shadow-2" style={{
                          // height: window.innerHeight 
                        }}>
                          <BootstrapTable
                            keyField="maPGC"
                            responsive
                            bordered={true}
                            striped={false}
                            defaultSorted={defaultSorted}
                            rowStyle={rowStyle}
                            // selectRow={selectRow}
                            // rowClasses ={rowClasses}
                            classes={
                              "table align-middle  table-nowrap table-check table-hover table-fixed "
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div>
                          <select
                            className="form-select"
                            value={filterCondition.Limit}
                            onChange={e => onChangeFilter("itemPerPage", Number(e.target.value))}
                          >
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                          </select>
                        </div>
                        <Col className="pagination pagination-rounded justify-content-end  inner-custom-pagination">
                          <Pagination
                            totalPage={Math.ceil(
                              data[0]?.totalRows / filterCondition.Limit
                            )}
                            changePage={(i) => onChangeFilter("page", i)}
                          />
                        </Col>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </Container>
      </div>
      <Contract_Detail ref={refDetail} />
    </React.Fragment>
  )
}

Debt_TheoDot.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Debt_TheoDot))
