import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import { handleValidDate, Format_Currency } from "../../../common/common"
import ForSale_Filter from "./ForSale_Filter"
import {
  Col,
  Container,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"
import { IoAdd } from "react-icons/io5"

import { Button } from "antd"
import Pagination from "../../../components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteForSale,
  editForSale,
  geSanPham,
  getImageForsale,
  getStaffs,
} from "../../../store/actions"
import ToolBar from "components/Toolbar"
import LoadingBar from "components/LoadingBar"
import { AgencyService } from "services/Agency"
import ForSale_Detail from "./ForSale_Detail"
import CommonFunction from "components/CommonFunction"
import { SanPhamService } from "services/SanPhamService"
import FormInformationBDS from "./FormInformationBDS"
import { CommonService } from "services/Common"
import { IoEyeSharp } from "react-icons/io5"

import "./index.scss"
const ForSaleIndex = props => {
  const dispatch = useDispatch()
  const refDetail = useRef(null)
  const refDetail2 = useRef(null)
  const history = useHistory()
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [modalImage, setModalImage] = useState(false)
  const [item, setItem] = useState([])
  const [modalDetail, setModalDetail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataDetail, setDataDetail] = useState({})
  const [data, setData] = useState([])
  const [nhuCau, setNhuCau] = useState([])

  const [phuongHuong, setPhuongHuong] = useState([])
  const [loaiDuong, setLoaiDuong] = useState([])
  const [phapLy, setPhapLy] = useState([])
  const [trangThai, setTrangThai] = useState([])
  const [khoangGiaThue, setKhoangGiaThue] = useState([])
  const { staffs } = useSelector(state => ({
    staffs: state.Staff.staffs,
  }))

  let preyear = new Date().setFullYear(new Date().getFullYear() - 5)
  const defaultCondition = {
    inputSearch: "",
    DuAn: [],
    TuNgay: new Date(preyear),
    DenNgay: new Date(),
    MaTT: 0,
    MaNhuCau: 1,
    Offset: 1,
    Limit: 20,
  }
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maBC !== row.maBC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
    const openInNewTab = value => {
      let url =
        "/#/chi-tiet-san-pham/id=" +
        value.maBC +
        "&mact=" +
        localStorage.getItem("TenCTDKVT")
      const newWindow = window.open(url, "_blank", "noopener,noreferrer")
      if (newWindow) newWindow.opener = null
    }
  const Columns = [
    {
      dataField: "stt",
      text: "STT",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.stt}
        </Link>
      ),
    },
    {
      dataField: "tenTT",
      text: "Trạng thái",
      sort: true,
    },
    {
      dataField: "MaNhuCau",
      text: "Nhu cầu",
      formatter: (cellContent, row) => (
        <p>{nhuCau?.find(v => v.ID === row.maNhuCau)?.TenLoai}</p>
      ),
    },

    {
      dataField: "tenLBDS",
      text: "Tên loại BĐS",
      sort: true,
    },
    {
      dataField: "soNha",
      text: "Số nhà",
      sort: true,
      formatter: (cellContent, row) => (
        <p
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => refDetail2.current.show(row)}
        >
          {row.soNha}
        </p>
      ),
    },
    {
      dataField: "tenDuong",
      text: "Đường",
      sort: true,
    },
    {
      dataField: "tenXa",
      text: "Tên xã",
      sort: true,
    },

    {
      dataField: "tenHuyen",
      text: "Tên huyện",
      sort: true,
    },
    {
      dataField: "tenTinh",
      text: "Tên tỉnh",
      sort: true,
    },
    {
      dataField: "hoTenKH",
      text: "Tên khách hàng",
      sort: true,
    },
    {
      dataField: "dienThoai",
      text: "Điện thoại",
      sort: true,
    },
    {
      dataField: "dienTich",
      text: "Diện tích",
      sort: true,
    },
    {
      dataField: "donGia",
      text: "Đơn giá",
      sort: true,
      formatter: (cellContent, row) => Format_Currency(row.donGia),
    },
    {
      dataField: "thanhTien",
      text: "Thành tiền",
      sort: true,
      formatter: (cellContent, row) => Format_Currency(row.thanhTien),
    },
    {
      dataField: "tenLD",
      text: "Tên LD",
      sort: true,
    },
    {
      dataField: "huongCua",
      text: "Hướng cửa",
      sort: true,
    },
    {
      dataField: "dacTrung",
      text: "Đặc trưng",
      sort: true,
    },
    {
      dataField: "dauOto",
      text: "Đậu Ô tô",
      sort: true,
      formatter: (cellContent, row) => (row.dauOto ? "Có" : "Không"),
    },
    {
      dataField: "tangHam",
      text: "Tầng hầm",
      sort: true,
      formatter: (cellContent, row) => (row.tangHam ? "Có" : "Không"),
    },
    {
      dataField: "isCanGoc",
      text: "Căn góc",
      sort: true,
      formatter: (cellContent, row) => (row.isCanGoc ? "Có" : "Không"),
    },
    {
      dataField: "isThangMay",
      text: "Thang máy",
      sort: true,
      formatter: (cellContent, row) => (row.isThangMay ? "Có" : "Không"),
    },
    {
      dataField: "soDK",
      text: "Số ĐK",
      sort: true,
    },
    {
      dataField: "ngayDK",
      text: "Ngày nhập",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.ngayDK),
    },
    {
      dataField: "kyHieuKH",
      text: "Ký hiệu",
      sort: true,
    },
    {
      dataField: "isBan",
      text: "Bán",
      sort: true,
      formatter: (cellContent, row) => (row.isBan ? "Có" : "Không"),
    },
    {
      dataField: "diaChi",
      text: "Địa chỉ",
      sort: true,
    },
    {
      dataField: "tienIch",
      text: "Tiện ích",
      sort: true,
    },
    {
      dataField: "huongBC",
      text: "Hướng BC",
      sort: true,
    },
    {
      dataField: "tenNguon",
      text: "Tên nguồn",
      sort: true,
    },
    {
      dataField: "tenPL",
      text: "Tên PL",
      sort: true,
    },
    {
      style: {
        backgroundColor: "white",
        position: "sticky",
        right: 0,
        paddingLeft: "0px",
      },
      headerStyle: {
        backgroundColor: "rgb(236, 240, 247)",
        position: "sticky",
        right: 0,
      },
      formatter: (cellContent, row) => (
        <div
          className="d-flex gap-3"
          style={{ boxShadow: "-5px 0 5px -5px #333" }}
        >
          <div
            style={{ cursor: "pointer" }}
            className="text-warning ps-3"
            onClick={() => openInNewTab(row)}
          >
            {/* <Link to={`forsale/details-bds/${row.maBC}`}> */}
            <IoEyeSharp style={{ marginRight: "-15px" }} />
            {/* </Link> */}
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="text-warning ps-3"
            onClick={() => refDetail.current.update(row)}
          >
            <i className="pi pi-pencil" />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => remove(row)}
            className="text-danger"
          >
            <i className="pi pi-trash" />
          </div>
        </div>
      ),
    },
  ]

  const remove = async item => {
    CommonFunction.confirmRemove({
      message: "Bạn có chắc muốn xóa bản ghi?",
      header: "Xác nhận",
      accept: async () => {
        let _res = await AgencyService.ForSale.removeCanBan({
          MaBC: [item.maBC],
        })
        if (_res.status === 2000) {
          await filter(filterCondition)
        }
      },
    })
  }
  const loadCategory = async () => {
    let _resGetLoai = await SanPhamService.NhuCauMuaThue.GetLoai.get({
      isSanPham: true,
    })
    if (_resGetLoai?.data) {
      if (_resGetLoai.data.length > 0) {
      }
      setNhuCau(_resGetLoai?.data)
    }

    CommonService.getLoadDuong().then(res => {
      setLoaiDuong(res.data || [])
    })
    CommonService.getPhapLy().then(res => {
      setPhapLy(res.data || [])
    })
    AgencyService.ForSale.getTrangThai().then(res => {
      setTrangThai(res.data || [])
    })
    SanPhamService.BanChoThue.getKhoangGia().then(res => {
      setKhoangGiaThue(res.data || [])
    })
    AgencyService.ForSale.getPhuongHuong().then(res => {
      setPhuongHuong(res.data || [])
    })
    // dispatch(geSanPham())
    dispatch(getStaffs({ inputSearch: "", Offset: 1, Limit: 1000 }))
  }
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    _filter.DuAn =
      _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : "all"
    let res = await AgencyService.ForSale.filter(_filter)
    setData(res.data || [])
    setLoading(false)
  }
  useEffect(() => {
    ;(async () => await loadCategory())()
    filter(filterCondition)
  }, [])
  const handleEdit = () => {
    dispatch(editForSale({ ...item[0] }))
    history.push("/forsale/add")
  }
  const toggleImage = () => {
    setModalImage(!modalImage)
  }
  const onClickXemAnh = () => {
    dispatch(getImageForsale({ MaBC: item[0]?.maBC }))
    toggleImage()
  }
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {
    const arrDelete = []
    itemDL?.map(i => {
      arrDelete.push(i.maBC)
    })
    if (arrDelete.length > 0) {
      dispatch(deleteForSale({ MaBC: arrDelete }))
    }
  }, [])

  const handleAdd = () => {
    dispatch(editForSale(null))
    history.push("/forsale/add")
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  // useEffect(() => {
  //   let time
  //   if (!time) {
  //     time = setTimeout(() => {
  //       dispatch(
  //         getForSale({
  //           TuNgay: dateFrom,
  //           DenNgay: dateTo,
  //           DuAn: maDuAn,
  //           inputSearch: textSearch,
  //           MaTT: maTT,
  //           KhoangGia: maKhoangGia,
  //           KhoangDT: maKhoangDT,
  //           MaLBDS: maLBDS,
  //           Offset: page,
  //           Limit: itemPerPage,
  //         })
  //       )
  //       setItem([])
  //     }, [1000])
  //   }
  //   return () => {
  //     clearTimeout(time)
  //   }
  // }, [textSearch])

  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  const afterSubmit = () => {
    filter(filterCondition)
  }
  return (
    <React.Fragment>
      <div className="page-content ban-chothue">
        <MetaTags>
          <title>Cần bán | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                <Button
                  icon={<IoAdd />}
                  onClick={() => refDetail.current.show()}
                >Thêm</Button>
                {item.length === 1 && (
                  <Button
                    label="Sửa"
                    icon="mdi mdi-pencil"
                    className="p-button-outlined p-button-warning mr-1"
                    onClick={handleEdit}
                  />
                )}
                {item.length >= 1 && (
                  <Button
                    label="Xóa"
                    icon="mdi mdi-delete"
                    className="p-button-outlined p-button-danger mr-1"
                    onClick={handleDelete}
                  />
                )}
                {item.length >= 1 && (
                  <ButtonDropdown
                    className="me-2"
                    isOpen={modalDropBtn}
                    toggle={() => toggleDropdownBtn()}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn btn-info rounded"
                    >
                      Hành động <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {item.length === 1 && (
                        <DropdownItem onClick={() => onClickXemAnh()}>
                          <i className="bx bx-search-alt me-1" />
                          Xem ảnh
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </div>
            )}
            right={() => (
              <ForSale_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
                nhuCau={nhuCau}
                phuongHuong={phuongHuong}
                loaiDuong={loaiDuong}
                phapLy={phapLy}
                trangThai={trangThai}
                staffs={staffs}
                khoangGiaThue={khoangGiaThue}
                maNhuCau={filterCondition?.MaNhuCau}
              />
            )}
          />
          <React.Fragment>
            <LoadingBar loading={loading} />
            <div className="table-responsive table-scrollable shadow-2">
              <BootstrapTable
                data={data === "[]" ? new Array([]) : data}
                columns={Columns}
                keyField="maBC"
                responsive
                bordered={true}
                striped={false}
                defaultSorted={defaultSorted}
                // selectRow={selectRow}
                classes={
                  "table align-middle  table-nowrap table-check table-hover table-fixed table-striped table-bordered"
                }
                headerWrapperClasses={"table-light"}
              />
            </div>
            <Row className="align-items-md-center mt-30">
              <Col className="inner-custom-pagination d-flex">
                <div className="d-inline">
                  <select
                    className="form-select"
                    value={filterCondition.Limit}
                    onChange={e =>
                      onChangeFilter("itemPerPage", Number(e.target.value))
                    }
                  >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                  </select>
                </div>
                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                  <Pagination
                    totalPage={Math.ceil(
                      data[0]?.TotalRows / filterCondition.Limit
                    )}
                    changePage={i => onChangeFilter("page", i)}
                  />
                </Col>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
      {/* <ForSale_Detail ref={refDetail} afterSubmit={afterSubmit} nhuCau={nhuCau} /> */}
      <ForSale_Detail
        ref={refDetail}
        afterSubmit={afterSubmit}
        nhuCau={nhuCau}
        phuongHuong1={phuongHuong}
        loaiDuong1={loaiDuong}
        phapLy1={phapLy}
        trangThai1={trangThai}
        staffs1={staffs}
      />
      <FormInformationBDS ref={refDetail2} />
    </React.Fragment>
  )
}

ForSaleIndex.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ForSaleIndex))
